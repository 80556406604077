import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Card from '../components/Card'
import appsStyles from './apps.module.scss'
import AppIcon from '../components/AppIcon'

const Apps = () => {
    return (
        <Layout className={appsStyles.Layout}>
            <h1 className={appsStyles.title}>Hey, well would you look at those apps!</h1>
            <h2>Here are the apps that I've published in the App Store since beginning my iOS development journey in the spring of 2018</h2>
            <div className="CardGroup">
                <div className="AppIcon">
                <Link to="/pushit"><AppIcon image={require("../images/logo-pushit.png")} /></Link>
                </div>
                <div className="AppIcon">
                <Link to="/breadbox"><AppIcon image={require("../images/logo-breadbox.png")} /></Link>
                </div>
                <div className="AppIcon">
                <Link to="/waker"><AppIcon image={require("../images/logo-waker.png")} /></Link>
                </div>
            </div>
        </Layout>
    )
}

export default Apps
