import React from 'react'
import { Link } from 'gatsby'
import headerStyles from './header.module.scss'

const Header = () => {

    return (
        <header className={headerStyles.header}>
            <nav className={headerStyles.nav}>
                <Link className={headerStyles.navBrand} to="/"><img src={require('../images/logo-jsonify.png')} height="50"></img></Link>
                <div className={headerStyles.navList}>
                    <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">About</Link>
                    <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/apps">Apps</Link>
                    <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contact</Link>
                </div>
            </nav>
        </header>
    )
}

export default Header
