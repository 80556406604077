import React from 'react'
import './AppIcon.scss'

const AppIcon = (props) => {
    return (
        <div className="AppIcon">
            <img src={props.image} />
        </div>
    )
}

export default AppIcon
