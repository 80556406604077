import React from 'react'
import './Card.scss'

const Card = (props) => {
    return (
        <div className="Card">
            <img src={props.image} />
        </div>
    )
}

export default Card
